
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import { boolean } from "yup/lib/locale";
import CustomTableBottomPagination from "@/components/customTables/CustomTableBottomPagination.vue";

/* eslint-disable */
export default defineComponent({
  name: "CustomTableInvoices",
  data() {
    return {
      rowsToShow: [""],
    };
  },
  components: {
    Dropdown2,
    CustomTableTopFilter,
    CustomTableBottomPagination,
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    filterStatus: Array,
    pages: Number,
    showDownloadBtn: {
      type: Boolean,
      default: true,
    },
    showPendingQtt: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    itemsPerPage: Number,
    totalItems: Number,
  },
  computed: {},
  emit: [
    "paginatorHandler",
    "updateStatus",
    "queryForOccurences",
    "downloadPdf",
    "updateFetchPage",
  ],
  methods: {
    showRow(rowId) {
      return this.rowsToShow.includes(rowId);
    },

    /*
     * ----------------DATA MANIPULATION----------------
     */

    toggleShowRowDetail(e, rowId) {
      const index = this.rowsToShow.indexOf(rowId);
      const iconElement =
        e.target.closest("svg") || e.target.querySelector("svg");
      const button = e.target.closest("button");
      if (index > -1) {
        this.rowsToShow.splice(index, 1);
        iconElement.style.transform = "rotate(0deg)";
        button.classList.remove("active");
        button.blur();
        return;
      } else {
        this.rowsToShow.push(rowId);
        iconElement.style.transform = "rotate(180deg)";
        button.classList.add("active");
        return;
      }
    },

    /*
     * ------------------EMITS------------------
     */
    updateFetchPage(page, pagesSize) {
      this.$emit("updateFetchPage", page, pagesSize);
    },
    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForOccurences(searchInput) {
      this.$emit("queryForOccurences", searchInput);
    },
    downloadPdf(document) {
      this.$emit("downloadPdf", document);
    },

    /*
     * -------------------UTILS--------------------
     */
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString("pt-pt").replaceAll("/", ".");
    },

    showValues(item) {
      if (item.strAbrevTpDoc == "CGRMM" || item.strAbrevTpDoc == "CGTR") {
        return false;
      }
      return true;
    },

    /*
     * -------------------STYLES-------------------
     */

    color(elem) {
      let str = "badge-light-";
      let color = elem === "Em Curso" ? "success" : "primary";
      return str + color;
    },
    rowColor(docId) {
      const index = this.list!.findIndex((item: any) => item.id == docId);
      if (index! % 2 == 0) {
        return "background-color: #DDF3FF;";
      } else {
        return "background-color: rgba(255, 255, 255, 0.07);";
      }
    },

    orderStatusColors(status) {
      switch (status.toUpperCase()) {
        case "CONCLUÍDA":
          return "background-color: #d9efcb; color: #3d9a01 !important; min-width:150px;";
        case "EM PROCESSAMENTO":
          return "background-color: #c6e3ec; color: #1ab3d4 !important; min-width:150px;";
        default:
          return "background-color: #e4ebf0; color: #181c32 !important; min-width:150px;";
      }
    },
  },
});
